var xjs = xjs || {};

// Time counter
//-----------------------------------------------------------------
xjs.inview = function() {
   

    $('.animate').one('inview', function(event, isInView) {
        if (isInView) {
            $(this).addClass('animated');
        } else {
            // $(this).removeClass('animated');
        }
    });

};


$(document).ready(function (){
    xjs.inview();
});
